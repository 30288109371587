<template>
  <v-row class="fill-height ma-0">
    <NumbersList ref="numberList" @loading="e => loading = e" />

    <v-col
      v-if="$vuetify.breakpoint.smAndUp"
      cols="12"
      md="8"
      lg="8"
      class="pa-0 voicemails-col"
    >
      <div class="d-flex fill-height white">
        <div class="w-100" style="position: relative">
          <v-toolbar v-if="voicemailItem" elevation="0">
             <template>
              <v-toolbar-title v-if="hasCallName && voicemailItem.direction === 'in'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ voicemailItem.caller_first_name }} {{ voicemailItem.caller_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else-if="hasCallName && voicemailItem.direction === 'out'" class="pa-0 d-flex flex-column">
                <span class="fz-20">{{ voicemailItem.callee_first_name }} {{ voicemailItem.callee_last_name }}</span>
                <span class="fz-14">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
              <v-toolbar-title v-else class="pa-0">
                <span class="fz-20">{{ formatPhone(getCallNumber) }}</span>
              </v-toolbar-title>
            </template>
            <v-spacer></v-spacer>

            <!-- <v-tooltip bottom color="dark">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-video</v-icon>
                </v-btn>
              </template>
              <span>Video call</span>
            </v-tooltip> -->
            <v-tooltip bottom color="dark">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="call">
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </template>
              <span>Call</span>
            </v-tooltip>

            <ActionsMenu @reload="$refs.numberList.loadVoicemails(true)" />
          </v-toolbar>
          <v-divider></v-divider>
          <div
            v-if="voicemailItem"
            style="height: calc(100% - 68px); padding-top: 24px"
            class="white d-flex flex-column align-center justify-center voicemailVideo"
          >
            <template>
              <v-avatar color="primary" size="72">
                <v-icon size="50" color="#fff">mdi-account</v-icon>
              </v-avatar>
              <p class="caption mt-2 muted--text">{{ formatDate(voicemailItem.createdAt) }}</p>
              <p v-if="!voicemailItem.transcripted_text">Transcription not available</p>
              <v-list v-else class="voicemails-list invisible-scrollbar"
                      style="padding-bottom: 72px">
                <v-list-item>
                  <v-list-item-content class="w-100 voicemail">{{ voicemailItem.transcripted_text }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <!-- <p class="muted--text">You mentioned you know. Yes. Oh my God.</p> -->
          </div>

          <v-footer
            v-if="voicemailItem"
            class="pa-0"
            style="position: absolute; bottom: 0; width: 100%"
          >
            <v-container fluid style="background-color: #fff" class="d-flex justify-center">
              <v-row class="py-0" style="width:418px;flex:unset">
                <v-col cols="12" class="d-flex align-center pt-3 pb-4">
                  <v-btn icon large @click="togglePlay">
                    <v-icon>{{ paused ? 'mdi-play' : 'mdi-pause' }}</v-icon>
                  </v-btn>
                  <div style="width: 100%">
                    <v-slider
                      @end="changeTimePoint"
                      @mousedown="barIsClicked = true"
                      @mouseup="barIsClicked = false"
                      v-model="time.percentage"
                      class="slider"
                    ></v-slider>
                    <v-layout justify-space-between class="mt-1">
                      <span class="caption">{{formatTime(time.current)}}</span>
                      <span class="caption">{{formatTime(time.total)}}</span>
                    </v-layout>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-footer>
          <v-list-item
            v-else-if="!loading"
            class="fill-height text-center"
            style="background-color:#f5f5f5;border: 1px solid #f5f5f5"
          >
            <v-list-item-content>
              <v-list-item-title style="font-size:32px">Hi {{userProfile ? userProfile.firstName : ''}}!</v-list-item-title>
              <v-list-item-subtitle class="mt-4">You’re all caught up</v-list-item-subtitle>
              <GoogleAdSense adSlot="6685324668" :stylesSm="showPeople" sequence="3" />
            </v-list-item-content>
          </v-list-item>
        </div>
        <People v-if="showPeople" @reload="$refs.numberList.loadVoicemails(true)" />
      </div>
    </v-col>
    <audio
      :src="source"
      ref="audio"
      @play="onPlay"
      @pause="onPause"
      @ended="ended"
      @timeupdate="updateTime"
      @durationchange="onDurationChange"
    ></audio>
  </v-row>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu.vue'
import NumbersList from '@/components/Numbers/NumbersList.vue'
import resizeMixin from '@/mixins/resize.mixin'
import People from '@/views/People'
import { mapState, mapGetters, mapMutations } from 'vuex'
import GoogleAdSense from '../components/GoogleAdSense.vue'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Voicemails',
  mixins: [resizeMixin],
  components: {
    GoogleAdSense,
    People,
    NumbersList,
    ActionsMenu
  },
  data: () => ({
    barIsClicked: false,
    // voicemailNumber: '+65 8384 0660',
    paused: true,
    time: {
      total: 0,
      current: 0,
      interval: null,
      percentage: 0
    },
    source: '',
    loading: false
  }),
  computed: {
    ...mapState(['showPeople']),
    ...mapGetters(['userProfile']),
    ...mapGetters('calls', ['voicemailItem']),
    hasCallName() {
      return (this.voicemailItem.direction === 'in' && (this.voicemailItem.caller_first_name || this.voicemailItem.caller_last_name)) ||
        (this.voicemailItem.direction === 'out' && (this.voicemailItem.callee_first_name || this.voicemailItem.callee_last_name))
    },
    getCallNumber () {
      return this.voicemailItem.direction === 'in' ? this.voicemailItem.ani : this.voicemailItem.dnis
    },
    audioIsExists () {
      return this.$refs.audio
    }
  },
  watch: {
    voicemailItem: {
      deep: true,
      handler () {
        this.setPlayerData()
        this.$refs.numberList.readVoicemail(this.voicemailItem)
      }
    }
  },
  created() {
    this.setPlayerData()
  },
  methods: {
    ...mapMutations('sip', ['changePhoneNumber']),
    formatPhone,
    setPlayerData () {
      if (!this.voicemailItem) return
      this.time.percentage = 0
      this.paused = true
      this.source = this.voicemailItem.b_leg_record_url
      // this.source = this.voicemailItem.direction === 'in' ? this.voicemailItem.a_leg_record_url : this.voicemailItem.b_leg_record_url
      // this.time.total = this.$refs.audio.duration
    },
    onPlay () {
      this.paused = false
      this.updateTime()
    },
    onPause () {
      this.paused = true
      // clearInterval(this.time.interval)
    },
    updateTime () {
      const currentTime = this.$refs.audio?.currentTime
      const duration = this.$refs.audio.duration
      const percentage = (currentTime / duration) * 100
      this.time.current = currentTime
      if (!this.barIsClicked) {
        this.time.percentage = percentage
      }
    },
    changeTimePoint (event) {
      if (typeof event !== 'number') return
      const d = this.$refs.audio.duration
      this.$refs.audio.currentTime = (d * event) / 100
    },
    onDurationChange (e) {
      this.time.total = e.target.duration
    },
    togglePlay () {
      if (this.paused) {
        this.time.current = 0
        this.time.percentage = 0
        this.$refs.audio.play()
        this.paused = false
        return
      }
      this.ended()
    },
    ended () {
      this.paused = true
      // this.time.percentage = 0
      // this.time.current = 0
      this.$refs.audio.pause()
    },
    formatTime (time) {
      time = parseInt(time)
      const m = parseInt(time / 60)
      const s = time % 60
      return `${m}:${s < 10 ? `0${s}` : s}`
    },
    getPhoneNumber() {
      if (isNaN(+this.getCallNumber)) {
        return this.getCallNumber === this.callItem.ani ? this.callItem.dnis : this.callItem.ani
      }
      return this.getCallNumber
    },
    call() {
      this.changePhoneNumber(this.getPhoneNumber())
    }
  }
}
</script>

<style lang="scss" scoped>
.voicemailVideo {
  min-height: 300px;
}

.voicemails-col {
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.slider ::v-deep {
  & .v-slider__thumb {
    display: none;
  }
  & .v-input__control {
    height: 20px;
  }
  & .v-slider__track-container {
    height: 4px;
  }
}
.voicemails-list {
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 144px - 67px) !important;
}

.voicemail {
    background: #EEEEEE;
    border-radius: 24px;
    padding: 8px 16px;
}
</style>
