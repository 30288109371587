<template>
  <v-container>
    <Navbar />
    <alert-status v-if="$store.state.alertStatus !== null" />
    <v-row
      class="purchaseContent d-flex flex-row justify-center my-0 mx-auto"
      style="max-width: 1000px"
    >
      <v-col cols="12" md="7" sm="7" class="">
        <div class="my-8">
          <v-btn @click="$router.go(-1)" color="primary" class="mb-10" outlined>
            <v-icon left> mdi-arrow-left</v-icon>
            Back
          </v-btn>
          <div>
            <h4 class="grey-33 body-1 pb-1">Purchase Period</h4>
            <!-- <p class="body-1 mt-2 mb-8 caption gray-44">
              Manage FreeFone labels and contact numbers at
              <a href="https://contacts.freefone.com">contacts.freefone.com.</a>
              If you change a number in FreeFone Contacts,
              be sure to update your rules to use the new number.
            </p> -->
          </div>
          <!-- HIDE BY JIRA FREES-646 -->
          <!-- <div>
            <h4>Select plan</h4>
            <v-select
              v-model="planUuid"
              :items="planList"
              item-text="plan_name"
              item-value="plan_uuid"
              menu-props="offsetY"
              solo
              dense
              outlined
            >
              <template v-slot:append>
                <v-icon size="15px">mdi-chevron-down</v-icon>
              </template>
            </v-select>
          </div> -->
          <div>
            <!-- <h4 class="grey-33 body-2 font-weight-medium">Period</h4> -->
            <v-card class="mx-auto my-4" outlined :loading="loading">
              <v-radio-group
                v-model="radios"
                mandatory
                hide-details
                class="mt-0 pt-0"
              >
                <template v-for="(option, index) in priceOptions">
                  <v-list :key="index + 'option'" class="transparent py-0 mt-1">
                    <v-list-item>
                      <v-radio :value="option.value" :name="option.label">
                        <template v-slot:label>
                          <span class="grey-33 text-capitalize body-1">{{ option.label }}</span>
                        </template>
                      </v-radio>
                      <v-list-item-title class="text-right font-weight-bold">
                        ${{ option.value }}
                        <span class="gray-33 font-weight-regular text-capitalize fz-12">/ {{ option.label.replace('ly', '') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-divider v-if="index < priceOptions.length - 1" :key="index + 'divider'" class="mx-4 mb-2"></v-divider>
                </template>
                <!-- <v-list class="transparent py-0">
                  <v-list-item>
                    <v-radio value="$69.99" name="Yearly">
                      <template v-slot:label>
                        <span class="grey-33">Yearly</span>
                      </template>
                    </v-radio>
                    <v-list-item-title class="text-right font-weight-bold">
                      $69.99
                      <span class="gray-44 font-weight-light">/ Year</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list> -->
              </v-radio-group>
            </v-card>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5" sm="5" class="">
        <v-card class="mx-auto my-8" max-width="316" outlined :loading="loading">
          <v-card-title class="grey-33 body-1 pb-2">Checkout</v-card-title>
          <!-- <v-card-text class="caption gray-44">
            Manage FreeFone labels and contact numbers at <a href="https://contacts.freefone.com">contacts.freefone.com</a>.
          </v-card-text> -->
          <v-list class="transparent pb-0">
            <v-list-item>
              <v-list-item-title class="grey-33 body-1 text-capitalize">{{
                selectedPriceLabel
              }}</v-list-item-title>
              <v-list-item-subtitle class="grey-33 text-right body-2 fz-14 font-weight-semi-bold">
                {{ radios }} USD
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-divider class="mx-4"></v-divider>
          <v-list class="transparent pt-0">
            <v-list-item>
              <v-list-item-title class="grey-33 font-weight-bold"
                >Total:</v-list-item-title
              >
              <v-list-item-title class="grey-33 text-right font-weight-bold">
                {{ radios }} USD
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="purchase" class="w-100 stripeButton" outlined>
                <v-img
                  :src="require('../assets/svg/stripe.svg')"
                  width="75"
                  height="22"
                  contain
                />
              </v-btn>
            </v-list-item>
            <!-- <v-list-item>
              <v-btn class="w-100 paypalButton" outlined>
                <v-img
                  :src="require('../assets/svg/paypal.svg')"
                  width="44"
                  height="22"
                  contain
                />
              </v-btn>
            </v-list-item> -->
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import AlertStatus from '@/components/Alert'
import { mapActions } from 'vuex'

export default {
  name: 'SelectNumber',
  data: () => {
    return {
      radios: '',
      priceOptions: [
        { value: '', label: 'monthly' },
        { value: '', label: 'yearly' }
      ],
      planList: [],
      planUuid: '',
      loading: false
    }
  },
  computed: {
    selectedPriceLabel() {
      const selectedPrice = this.priceOptions.find((e) => e.value === this.radios)
      return selectedPrice ? selectedPrice.label : 'Monthly'
    }
  },
  components: {
    Navbar,
    AlertStatus
  },
  created () {
    this.loadPlans()
  },
  methods: {
    ...mapActions(['upgradePlan', 'errorAlert', 'getPlanList', 'createPayment']),
    async loadPlans() {
      try {
        this.loading = true
        const data = await this.getPlanList()
        if (!data || !data.data || !data.data.length) return
        this.planList = data.data
        const vip = this.planList.find(e => e.plan_name.toLowerCase().includes('vip'))

        this.planUuid = vip.plan_uuid
        this.radios = vip.plan_monthly_price
        this.priceOptions[0].value = vip.plan_monthly_price
        this.priceOptions[1].value = vip.plan_yearly_price
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    async purchase() {
      try {
        const payload = {
          plan_uuid: this.planUuid,
          duration_unit: this.selectedPriceLabel.replace('ly', ''),
          duration: 1
        }
        const data = await this.upgradePlan(payload)
        window.location.href = data.url
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.errorAlert(error)
        throw new Error(err)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.grey-33 {
  color: #333333 !important;
}

.body-1 {
  font-size: 16px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

.purchaseContent {
  max-width: 1000px;
}

.stripeButton {
  background: #635bff;
  border-radius: 4px;
  border: none;
}

.paypalButton {
  background: #f7c557;
  border-radius: 4px;
  border: none;
}

.numberSection {
  width: 100%;
  margin-top: 100px;
  max-width: 560px;
  margin-right: 205px;
}

.numberSelect {
  font-size: 16px;
  font-weight: 500;
  color: #5758b0;
}

.gray-44 {
  color: #828282;
}
</style>
